/* eslint-disable */
import React from "react";
import Select from "@layout/Select2";
import Datetime from "react-datetime";
import { isObject } from "@helpers";
import moment from "moment";
import PropTypes from "prop-types";
import SubmitContainer from "@layout/SubmitContainer";

export default class AdvancedSearch extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.setNow = this.setNow.bind(this);
    this.reset = this.reset.bind(this);
    this.onSearch = this.onSearch.bind(this);

    this.participationTypes = {
      onSite: "On-site only",
      onLine: "Online only",
      both: "On-site or online",
    };

    this.targetedKeys = [
      "dateFrom",
      "dateTo",
      "roomId",
      "minutesRangeFrom",
      "minutesRangeTo",
      "participationType",
    ];

    this.originalState = {
      minutesRangeFrom: "",
      minutesRangeTo: "",
      roomId: "",
      dateFrom: "",
      dateTo: "",
      groupId: "",
      hasHit: true,
      participationType: "both",
    };
    const currentState =
      (this.props.advancedSearchOptions[this.props.event.id] || {})[
        this.props.policyId
      ] || {};
    const populated = this.populateUserMeta(props.meta);
    this.state = Object.assign(
      {},
      populated,
      Object.keys(currentState).length || this.originalState
    );
  }
  UNSAFE_componentWillMount() {
    if (!this.props.roomsFetched) {
      this.props.loadRooms(this.props.event.id);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.meta) !== JSON.stringify(this.props.meta)) {
      this.setState({ ...this.populateUserMeta(nextProps.meta) });
    }
  }
  populateUserMeta(meta) {
    const fields = ["roomId", "hasHit", "participationType"];
    const response = {};
    if (meta.hasOwnProperty("minutesRange")) {
      response["minutesRangeFrom"] =
        meta.minutesRange.from >= 0 ? meta.minutesRange.from : "";
      response["minutesRangeTo"] =
        meta.minutesRange.to >= 0 ? meta.minutesRange.to : "";
    }
    if (meta.hasOwnProperty("dateRange")) {
      response["dateFrom"] = meta.dateRange.from
        ? moment(meta.dateRange.from, "YYYY-MM-DD HH:mm:ss").format(
            "DD-MM-YYYY HH:mm"
          )
        : "";
      response["dateTo"] = meta.dateRange.to
        ? moment(meta.dateRange.to, "YYYY-MM-DD HH:mm:ss").format(
            "DD-MM-YYYY HH:mm"
          )
        : "";
    }
    fields.map((f) => {
      if (meta.hasOwnProperty(f)) {
        response[f] = meta[f];
      }
    });
    return response;
  }

  onSearch() {
    const state = this.state;
    if (state.dateFrom && state.dateTo) {
      if (
        moment(state.dateFrom, "DD-MM-YYYY HH:mm").isAfter(
          moment(state.dateTo, "DD-MM-YYYY HH:mm") ||
            moment(state.dateTo, "DD-MM-YYYY HH:mm").isBefore(
              moment(state.dateFrom, "DD-MM-YYYYY HH:mm")
            )
        )
      ) {
        // invalid dates
        return false;
      }
    }

    if (
      (state.dateFrom &&
        !moment(state.dateFrom, "DD-MM-YYYY HH:mm").isValid()) ||
      (state.dateTo && !moment(state.dateTo, "DD-MM-YYYY HH:mm").isValid())
    ) {
      return false;
    }
    if (state.dateFrom) {
      state.dateFrom = moment(state.dateFrom, "DD-MM-YYYY HH:mm").format(
        "DD-MM-YYYY HH:mm"
      );
    }
    if (state.dateTo) {
      state.dateTo = moment(state.dateTo, "DD-MM-YYYY HH:mm").format(
        "DD-MM-YYYY HH:mm"
      );
    }
    const query = {
      hasHit: Boolean(this.state.hasHit),
      roomId: this.state.roomId || undefined,
      participationType: this.state.participationType,
      minutesRange: {
        from: this.state.minutesRangeFrom || "",
        to: this.state.minutesRangeTo || "",
      },
      dateRange: {
        from: this.state.dateFrom || "",
        to: this.state.dateTo || "",
      },
    };
    this.setState(state, () => {
      this.props.getParticipants({
        eventId: this.props.event.id,
        policyId: this.props.policyId,
        query,
      });
      this.props.setAdvancedSearchOptions(
        this.props.event.id,
        this.props.policyId,
        this.state
      );
      this.props.hideModal();
    });
  }
  onChange(e) {
    const { value } = e.target;
    const { name } = e.target;
    const newState = { ...this.state };

    newState[name] = value;

    this.setState(newState);
  }

  setNow() {
    const now = moment().format("DD-MM-Y HH:mm");
    this.setState(
      {
        dateFrom: now,
        dateTo: now,
      },
      () => {
        this.props.setAdvancedSearchOptions(
          this.props.event.id,
          this.props.policyId,
          this.state
        );
      }
    );
  }

  reset() {
    this.setState(this.originalState, () => {
      this.props.setAdvancedSearchOptions(
        this.props.event.id,
        this.props.policyId,
        {}
      );
      this.props.getParticipants({
        eventId: this.props.event.id,
        policyId: this.props.policyId,
        query: {
          p: 1,
          minutesRange: undefined,
          participationType: undefined,
          hasHit: undefined,
          dateRange: undefined,
        },
      });
      this.props.hideModal();
    });
  }
  onChangeNumber(e) {
    if (!isFinite(e.target.value)) return;
    e.target.value = parseInt(e.target.value);
    if (isNaN(e.target.value)) e.target.value = "";
    return this.onChange(e);
  }
  render() {
    return (
      <div className="form-container">
        <h2>Attendance Search</h2>
        <div className="form-group">
          <label>Select participants who</label>
          <Select
            isSearchable={false}
            isClearable={false}
            options={{
              1: "Participated in a session",
              0: "Did not participate in a session",
            }}
            onChange={(value) => this.setState({ hasHit: Number(value) })}
            value={Number(this.state.hasHit)}
          ></Select>
        </div>
        <div className="form-group">
          <label>Attendance Type</label>
          <Select
            isClearable={false}
            options={this.participationTypes}
            placeholder="Select Type"
            onChange={(value) =>
              this.onChange({ target: { name: "participationType", value } })
            }
            value={this.state.participationType}
          />
        </div>
        <div className="form-group">
          <label>Attendance Record (in minutes)</label>
          <div className="row">
            <div className="col-sm-6">
              <div className="field-type type-text kmb-text">
                <input
                  name="minutesRangeFrom"
                  placeholder="More than"
                  className="form-control"
                  onChange={(e) => this.onChangeNumber(e)}
                  value={this.state.minutesRangeFrom}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="field-type type-text kmb-text">
                <input
                  name="minutesRangeTo"
                  placeholder="Less than"
                  className="form-control"
                  onChange={(e) => this.onChangeNumber(e)}
                  value={this.state.minutesRangeTo}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Located in room</label>
          <Select
            options={this.props.rooms
              .map((r) => {
                return [r.id, r.name];
              })
              .reduce((prev, next) => ({ [next[0]]: next[1], ...prev }), {})}
            placeholder="Select Room"
            onChange={(value) => {
              this.onChange({ target: { name: "roomId", value: value || "" } });
            }}
            value={this.state.roomId}
          />
        </div>
        <div className="form-group">
          <label>Before or after the date</label>
          <div style={{ display: "flex", gap: 12, height: 40 }}>
            <div className="field-type type-datetime kmb-datetime">
              <div className="form-group">
                <Datetime
                  inputProps={{ placeholder: "Select date" }}
                  dateFormat="DD-MM-YYYY"
                  timeFormat="HH:mm"
                  value={this.state.dateFrom}
                  isValidDate={(current) => {
                    if (!this.state.dateTo) {
                      return true;
                    }
                    return current.isSameOrBefore(
                      moment(this.state.dateTo, "DD-MM-Y")
                    );
                  }}
                  onChange={(value) => {
                    if (isObject(value)) {
                      value = value.format("DD-MM-YYYY HH:mm");
                    }
                    return this.setState({
                      dateFrom: value,
                    });
                    // this.onChange({ target: { name: "dateFrom", value } })
                  }}
                />
                <span className="icon icon-date"></span>
              </div>
            </div>
            <div className="field-type type-datetime kmb-datetime">
              <div className="form-group">
                <Datetime
                  inputProps={{ placeholder: "Select date" }}
                  dateFormat="DD-MM-YYYY"
                  timeFormat="HH:mm"
                  value={this.state.dateTo}
                  isValidDate={(current) => {
                    if (!this.state.dateFrom) {
                      return true;
                    }
                    return current.isSameOrAfter(
                      moment(this.state.dateFrom, "DD-MM-YYYY")
                    );
                  }}
                  onChange={(value) => {
                    if (isObject(value)) {
                      value = value.format("DD-MM-YYYY HH:mm");
                    }
                    return this.setState({
                      dateTo: value,
                    });
                  }}
                />
                <span className="icon icon-date"></span>
              </div>
            </div>
            <button
              className="btn dark rounded"
              onClick={this.setNow}
              style={{ margin: 1 }}
            >
              Now
            </button>
          </div>
        </div>

        <SubmitContainer
          onCancel={this.reset}
          onSubmit={this.onSearch}
          submitText="Search"
          cancelText="Reset"
        />
      </div>
    );
  }
}

AdvancedSearch.propTypes = {
  meta: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  advancedSearch: PropTypes.func.isRequired,
  rooms: PropTypes.array.isRequired,
  policyGroups: PropTypes.any,
  toggleHit: PropTypes.func,
  setAdvancedSearchOptions: PropTypes.func.isRequired,
  advancedSearchOptions: PropTypes.object.isRequired,
};
