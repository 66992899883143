import React from "react";
import { api } from "@actions/ajax";
import {
  addNotification,
  hideModal,
  showNormalModal,
  hideNormalModal,
} from "@actions";
import offLineScanner from "@helpers/offline-scanner";
import notificationObj from "@helpers/notification-object";
import { isEmpty } from "@helpers";
import Auth from "@components/auth/Auth";
import { changeSection } from "@actions/editmenu";

export const LOGOUT_USER = "LOGOUT_USER";
export const RECEIVED_USER_GET = "RECEIVED_USER_GET";
export const UNSAVED_SCANS = "UNSAVED_SCANS";
export const NEW_NOTIFICATION = "NEW_NOTIFICATION";
export const NOTIFICATION_DISMISSING = "NOTIFICATION_DISMISSING";
export const NOTIFICATION_DISMISSED = "NOTIFICATION_DISSMISED";
export const RESET_USER_NOTIFICATIONS = "RESET_USER_NOTIFICATIONS";
export const SYNC_NOTIFICATIONS = "SYNC_NOTIFICATIONS";
export const MULTIPLE_NOTIFICATIONS = "MULTIPLE_NOTIFICATIONS";
export const ADJUST_ACTIVE_PANELS = "ADJUST_ACTIVE_PANELS";
export const DECORATE_USER = "DECORATE_USER";
export const CHANGE_SCREEN = "CHANGE_SCREEN";

export const changeScreen = (uid, screen) => {
  localStorage.setItem(`user_${uid}_screen`, screen);
  window.location.reload();
};

export const decorateUser = (roles, initialScreen) => {
  return {
    type: DECORATE_USER,
    roles,
    initialScreen,
  };
};

export const scanningAuth = (callback) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        showNormalModal(
          <Auth
            onSubmit={(password) => {
              dispatch(auth(password))
                .then((data) => {
                  resolve(data);
                  callback();
                })
                .catch((err) => {
                  callback();
                  dispatch(addNotification(`Error: ${err.message}`, "error"));
                  reject(err);
                });
              dispatch(hideNormalModal());
            }}
            onCancel={() => {
              callback();
              return dispatch(hideNormalModal());
            }}
          />,
          "Please enter your password."
        )
      );
    });
  };
};
export const changePassword =
  ({ token, password }) =>
  (dispatch) => {
    const request = {
      endpoint: "authenticate",
      action: "changePassword",
      body: {
        data: { token, password },
      },
      insecure: true,
    };
    return dispatch(api(request));
  };
export const forgotPassword =
  ({ email }) =>
  (dispatch) => {
    const request = {
      endpoint: "authenticate",
      action: "forgotPassword",
      body: {
        data: { email },
      },
      insecure: true,
    };
    return dispatch(api(request));
  };
export const login =
  ({ email, password }) =>
  (dispatch) => {
    const request = {
      endpoint: "authenticate",
      action: "login",
      body: {
        data: { username: email, password },
      },
      insecure: true,
    };
    return dispatch(api(request));
  };
export const logoutUser = () => (dispatch) => {
  window.localStorage.removeItem("bluetree_admin_token");
  dispatch({ type: LOGOUT_USER });
};
export const auth = (password) => (dispatch, getState) => {
  const state = getState();
  const { email } = state.appuser.data;

  const request = {
    endpoint: "authenticate",
    action: "login",
    body: {
      data: { username: email, password },
    },
  };

  return dispatch(api(request));
};

export const downloadHitsFile = (eventId, roomId, index = false) => {
  return (dispatch) => {
    offLineScanner.downloadFile(eventId, roomId);
    dispatch(hideNormalModal());
    setTimeout(() => dispatch(dismissNotification(index)), 300);
  };
};

export const dismissNotification = (index) => {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATION_DISMISSING,
      index,
    });

    setTimeout(() => {
      dispatch({
        type: NOTIFICATION_DISMISSED,
        index,
      });
    }, 300);
  };
};

export const resolveUnsavedHits = (
  title,
  message,
  eventId,
  roomId,
  notificationIndex
) => {
  return (dispatch) => {
    const footer = (
      <div>
        <button
          className="btn"
          onClick={() =>
            dispatch(downloadHitsFile(eventId, roomId, notificationIndex))
          }
        >
          Download hits file
        </button>
        <button
          className="btn"
          onClick={() => {
            const scansSync = offLineScanner.get(eventId, roomId);
            if (scansSync.length) {
              dispatch(sendHitsToServer(eventId, roomId, scansSync))
                .then((res) => {
                  offLineScanner.deleteChannel(eventId, roomId);
                  dispatch(hideNormalModal());
                  setTimeout(
                    () => dispatch(dismissNotification(notificationIndex)),
                    300
                  );
                  let message = "";
                  if (res.data.success > 0) {
                    message += `${res.data.success} ${
                      res.data.success === 1 ? "scan" : "scans"
                    } imported successfully. `;
                  }
                  if (res.data.fail > 0) {
                    message += `${res.data.fail} ${
                      res.data.fail === 1 ? "scan" : "scans"
                    }  failed to import.`;
                  }
                  dispatch(
                    addNotification(
                      message.trim(),
                      res.data.success === 0 ? "error" : "success"
                    )
                  );
                })
                .catch((err) => {
                  dispatch(
                    addNotification(
                      `Scan imported failed. Reason: ${err}`,
                      "error"
                    )
                  );
                });
            }
          }}
        >
          Send the hits to the server directly
        </button>
      </div>
    );
    dispatch(showNormalModal(message, title, footer));
  };
};

export const syncNotifications = (notifications) => {
  return (dispatch) => {
    dispatch({
      type: SYNC_NOTIFICATIONS,
      notifications,
    });
  };
};

export const resetUserNotifications = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_USER_NOTIFICATIONS,
    });
  };
};

export const newNotification = (notification) => {
  return (dispatch) => {
    dispatch({
      type: NEW_NOTIFICATION,
      notification,
    });
  };
};
export const uploadHitsToServer = (eventId, scans) => {
  const endpoint = "rooms";
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const query = {
        endpoint,
        action: "uploadScanFile",
        params: {
          eventId,
        },
        body: {
          scans,
        },
        contentType: "multipart/form-data",
      };

      dispatch(api(query))
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  };
};

export const sendHitsToServer = (
  eventId,
  roomId,
  scansSync,
  endpoint = "rooms"
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const query = {
        endpoint,
        action: "scan",
        params: {
          eventId,
          roomId,
        },
        body: {
          data: {
            scansSync,
          },
        },
      };

      dispatch(api(query))
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  };
};

export const getBackEndNotifications = () => (dispatch) =>
  dispatch(
    api({
      endpoint: "user",
      action: "getNotifications",
    })
  );

export const determineCallback = (section, refData) => (dispatch, getState) => {
  const state = getState();
  switch (section) {
    case "abstract_resubmission": {
      state.page.navigate(
        `/judge/abstracts/${refData.eventId}/${refData.abstractId}`
      );
      break;
    }
    case "allout": {
      state.page.navigate(
        `/administrator/events/${refData.eventId}/basic-setup`
      );
      setTimeout(() => dispatch(changeSection("rooms")), 500);
    }
  }
  dispatch(hideModal());
};

export const notificationsViewed = () => (dispatch) => {
  return dispatch(
    api({
      endpoint: "user",
      action: "notificationsRead",
      body: { data: {} },
    })
  );
};

export const adjustActivePanels =
  (eventId, roomId, activePanels) => (dispatch) => {
    dispatch({
      type: ADJUST_ACTIVE_PANELS,
      eventId,
      roomId,
      activePanels,
    });
  };

export const notificationHook = (n) => (dispatch) => {
  const { referenceData } = n;
  switch (n.notificationSectionName) {
    case "scanner": {
      // adjust room active panel
      return dispatch(
        adjustActivePanels(
          referenceData.eventId,
          referenceData.roomId,
          referenceData.numberOfActivePanels
        )
      );
    }
    default: {
      return;
    }
  }
};

export const notificationBuildHook = (n, _n) => {
  if (_n.notificationSectionName === "scanner") {
    n.resolvable = false;
  }
  return n;
};

export const newBackendNotification =
  (n, _return = false) =>
  (dispatch, getState) => {
    let title = n.notificationSectionDescription;

    Object.entries(n.referenceData).map(([r, k]) => {
      title = title.replace(`{{${r}}}`, k);
    });

    const notification = notificationBuildHook(
      notificationObj.init(
        {
          type: n.type,
          title,
          message: title,
          date: new Date(n.updatedAt.tz),
          dismissible: false,
          resolvable: true,
          resolveCallback: () =>
            dispatch(
              determineCallback(n.notificationSectionName, n.referenceData)
            ),
          viewed: n.viewed,
        },
        getState()
      ),
      n
    );

    if (!_return) {
      // it's a socket notification.
      // allow an optional hook
      dispatch(notificationHook(n));
    }

    return _return ? notification : dispatch(newNotification(notification));
  };

export const getUserNotifications = (reset = false) => {
  return (dispatch, getState) => {
    let state = getState();
    dispatch(getBackEndNotifications()).then((res) => {
      // check for front end
      // notifications
      const notifications = res.data?.notifications ?? [];

      if (notifications.length > 0) {
        dispatch({
          type: MULTIPLE_NOTIFICATIONS,
          notifications: notifications?.map((n) =>
            dispatch(newBackendNotification(n, true))
          ),
        });
      }

      const unsavedScans = offLineScanner.getAllByUser(state.appuser.data);

      if (reset === true) {
        dispatch(resetUserNotifications());
      }

      if (!isEmpty(unsavedScans)) {
        Object.entries(unsavedScans).map(([i, v]) => {
          Object.entries(v).map(([rid]) => {
            const title = "You have unsaved hits!";
            const message = `You have unsaved hits in event with id #${i} and room with id #${rid}`;

            state = getState();
            const notificationIndex = state.appuser.notifications.length;
            const notification = notificationObj.init(
              {
                type: "danger",
                title,
                message,
                date: new Date(),
                dismissible: false,
                resolveCallback: () => {
                  dispatch(
                    resolveUnsavedHits(
                      title,
                      message,
                      i,
                      rid,
                      notificationIndex
                    )
                  );
                },
              },
              state
            );
            dispatch(newNotification(notification));
          });
        });
      }

      dispatch({
        type: UNSAVED_SCANS,
        unsavedScans,
      });
    });
  };
};

export const getUser = (endpoint = "user") => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const query = {
        endpoint,
        action: "get",
        cache: false,
      };
      return dispatch(api(query))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.warn(err);
          reject(err);
        });
    });
  };
};
